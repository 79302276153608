.add-form {
  width: 800px;
  margin: 50px auto;

  label {
    display: block;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  small {
    font-weight: normal;
  }

  input,
  textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
  }

  button {
    padding: 10px;
    width: 100%;
  }
}
